.loader {
  border-style: solid;
  border-radius: 50%;
  animation: spin 2s linear infinite;
}
.loader.sm {
  width: 36px;
  height: 36px;
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #3498db; /* Blue */
}
.loader.md {
  width: 54px;
  height: 54px;
  border: 6px solid #f3f3f3; /* Light grey */
  border-top: 6px solid #3498db; /* Blue */
}
.loader.lg {
  width: 72px;
  height: 72px;
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #3498db; /* Blue */ 
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
