.container-map {
  background-color: red;
  width: 100%;
  padding-top: 100%; /* 1:1 Aspect Ratio */
  position: relative; /* If you want text inside of it */
}

/* If you want text inside of the container */
.map {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
