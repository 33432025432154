.btn-primary {
  @apply bg-blue-400 hover:bg-blue-500 text-gray-100 flex justify-center  p-3 rounded-lg tracking-wide font-semibold shadow-lg cursor-pointer transition ease-in duration-500
}

.btn-outline-primary {
  @apply border border-blue-400 hover:border-blue-500 hover:bg-blue-100 text-gray-800 flex justify-center p-3 rounded-lg tracking-wide font-semibold shadow-lg cursor-pointer transition ease-in duration-500
}

.btn-warning {
  @apply bg-red-400 hover:bg-red-500 text-gray-100 flex justify-center  p-3 rounded-lg tracking-wide font-semibold shadow-lg cursor-pointer transition ease-in duration-300
}

.btn-outline-warning {
  @apply border border-red-400 hover:border-red-500 hover:bg-red-100 text-gray-800 flex justify-center p-3 rounded-lg tracking-wide font-semibold shadow-lg cursor-pointer transition ease-in duration-500
}